/* General */
$fontSize: 14px;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;

/* Menu */
$scrollPanelBgColor: #aaaaaa;
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;

/* Menu Dark*/
$menuDarkBgColorFirst: #4d505b;
$menuDarkBgColorLast: #3b3e47;
$menuitemDarkColor: #ffffff;
$menuitemDarkHoverColor: #0388e5;
$menuitemDarkActiveColor: #0388e5;
$menuitemDarkActiveBgColor: #2e3035;
$menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Menu Light*/
$menuBgColorFirst: #f3f4f9;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #232428;
$menuitemHoverColor: #f3831a;
$menuitemActiveColor: #f3831a;
$menuitemActiveBgColor: #ffffff;
$menuitemBorderColor: rgba(247, 192, 121, 0.6);

/* sidebar */
$sidebarWidth: 20vw;
$sidebarTopBgColor: #e67e22;
$sidebarBottomBgColor: rgba(255, 255, 255, 0);
$sidebarBgColor: #f8ecca;

/* Topbar */
$topbarLeftBgColor: #212121;
$topbarRightBgColor: rgba(255, 255, 255, 0);
$topbarBgColor: #f60;
$topbarLeftPercent: 20%;
$topbarRightPercent: 35%;

$topbarItemBadgeBgColor: #02571e;
$topbarItemBadgeColor: #ffffff;
$topbarItemColor: #ffffff;
$topbarItemHoverColor: #8d4807;
$topbarSearchInputBorderBottomColor: #ffffff;
$topbarSearchInputColor: #ffffff;

/* Footer */
$footerBgColor: #ffffff;

/* pillars */
$pillars-header-font-color: #202020;
$pillars-header-font-size: 1rem;
$governanceBarLeftBgColor: #e57373;
$governanceBarRightBgColor: #e57373;

$educationBarLeftBgColor: #fff176;
$educationBarRightBgColor: #fff176;

$socialBarLeftBgColor: #a1887f;
$socialBarRightBgColor: #a1887f;

$healthBarLeftBgColor: #7986cb;
$healthBarRightBgColor: #7986cb;

$workBarLeftBgColor: #81c784;
$workBarRightBgColor: #81c784;

$financeBarLeftBgColor: #64b5f6;
$financeBarRightBgColor: #64b5f6;

$errorColor: #BA0000;

@import './Sass/layout';
