.map {
  width: 100%;
  height: 100%;
}
.ol-popup {
  position: absolute;
  background-color: rgba(256, 256, 256, 0.85);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 20px;
  left: -50px;
  min-width: 6em;

  .popup-content {
    div {
      display: flex;
      min-width: max-content;
      font-size: 13px;
      align-items: center;
      font-weight: bold;
    }
  }
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.export-container {
  position: absolute;
  top: 0;
  background-color: white;
  cursor: pointer;
}
