.error {
  /* margin-left: 10px; */
  color: #ff0000;
}
.input {
  position: relative;
  display: flex;
  /* new styles from here */
  border: 1px solid #ecebed;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  background: #ffffff;
}
.input-group-addon {
  display: inline-flex;
  min-width: 3em;
  align-items: center;
  justify-content: center;
}
.input-control {
  flex: 1;
  border: none;
  min-height: 35px;
  width: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 5px;
  background-color: #ffffff !important;
  border-radius: 10px;
}
.input-error-control {
  border: 1px solid #ff0000 !important;
}
.button {
  height: 40px;
  background: linear-gradient(
    205.41deg,
    #ffb74d 1.55%,
    #ff9b5c 38.86%,
    #ff8a65 60.46%
  );
  mix-blend-mode: normal;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer;
}
.btn-text {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 10px;
  padding: 5px;
}

.pass-eye-con {
  display: flex;
  align-items: center;
  padding: 10px;
}
