/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  // .mcd-menu{
  //   overflow: hidden;
  // }
} 

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  // .mcd-menu{
  //   overflow: hidden;
  // }
} 

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    // .app-wrapper{
    //     @include linear-gradient($topbarBgColor, 35%, #fff, 10%, $topbarBgColor);
    // }
    .container{
      
        .sidebar{
     
          .app-profile{
            height: 94px;
            .profile-img{
                height: 60px;
                width:60px;
            }
          }
        }
    }
    .mcd-menu{
      overflow: hidden;
    }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    // .app-wrapper{
    //     @include linear-gradient($topbarBgColor, 25%, #fff, 10%, $topbarBgColor);
    // }
    .container{
      
        .sidebar{
     
          .app-profile{
            height: 171px;
            .profile-img{
                height: 100px;
                width:100px;
            }
          }
        }
    }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    // .app-wrapper{
    //     @include linear-gradient($topbarBgColor, 25%, #fff, 10%, $topbarBgColor);
    // }
    .container{
      
        .sidebar{
     
          .app-profile{
            height: 171px;
            .profile-img{
                height: 100px;
                width:100px;
            }
          }
        }
    }
}