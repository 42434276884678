.row{
    flex:1;
    display:flex;
    flex-wrap: wrap;
}
.input-error{
    margin-left:10px;
    color:$errorColor;
    
}
.btn{
    margin-left:10px;
    padding:0 10px;
    position: relative;
    display: flex;
    color:#fff;
    background: $topbarBgColor;
    height: 30px;
    border-radius: 5px;
    font-size: 1rem;
    border:1px solid #cccccc;
    cursor: pointer;

}
.btn:hover{
    box-shadow: 0px 0px 2px rgba(68,68,68,0.6);
}
.btn:active{
    transform: translateY(3px)
}
.input-group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items:stretch;
    // width:100%;
    margin:10px;
    label{
        position: absolute;
        top:0px;
        right:0px;
        margin:5px;
        color:rgba(0,0,0,0.2);
        font-size: 0.8rem;
        // background: #fff;
        // border:1px solid #cccccc;
        // border-radius: 3px;
        padding:5px;
    }
    .input-group-addon{
        display: inline-flex;
        min-width: 2em;
        border-width: 1px;
        border-style: solid;
        border-color: #a6a6a6;
        background-color: #eaeaea;
        color: #848484;
        padding: 0.429em;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        align-items: center;
    }
    .input-error-control{
        border:1px solid $errorColor !important;
    }
    .input-control{
        display: block;
        flex:1;
        width: 1%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-left:0;
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}