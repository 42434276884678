.select {
  [class*='-control'] {
    position: relative;
    display: flex;
    border: 1px solid #ecebed;
    border-radius: 5px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
    background: #ffffff;
  }

  [class*='-menu'] {
    z-index: 9;
  }
  svg {
    color: #474747;
  }
  [class*='IndicatorsContainer'] {
    span {
      display: none;
    }
  }
}

.select-container {
  position: relative;
  border: 1px solid #ecebed;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  background: #ffffff;
  margin: 5px;
}

.role-selector {
  [class*='-placeholder'] {
    display: flex;
    align-items: center;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      // content: url("/images/login/role.png");
      padding-right: 14px;
    }
  }
  [class*='-singleValue'] {
    display: flex;
    align-items: center;
    font-size: 16px;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      // content: url("/images/login/role.png");
      padding-right: 12px;
    }
  }
}

.language-selector {
  // min-width: 11em;
  // margin: 5px;
  [class*='-placeholder'] {
    display: flex;
    align-items: center;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      content: url('../../../public/images/login/language.svg');
      padding-right: 14px;
    }
  }
  [class*='-singleValue'] {
    display: flex;
    align-items: center;
    font-size: 16px;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      content: url('../../../public/images/login/language.svg');
      padding-right: 12px;
    }
  }
}
