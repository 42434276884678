div {
  user-select: none;
}

.sidebar {
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: white;
  border: 1px solid #ecebed;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
}

.top {
  top: 0;
  display: flex;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.tab {
  border-right: 0.5px solid #cecece;
  width: 18em;
  overflow: hidden;
}

.bottom {
  bottom: 1.5em;
  right: 1.5em;
  padding: 10px;
  border-radius: 1rem;
}

.bottom-left {
  display: flex;
  flex-direction: column-reverse;
  bottom: 1.5em;
  left: 1.5em;
  padding: 0.3em;
  border-radius: 1rem;
  /* width: 25em; */
  transition: width 0.9s ease-in;
}

.shrinked {
  width: 25em;
}

.expanded {
  width: 50em;
}

.bottom-menus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.spi {
  width: 40%;
  background-color: #f5f5f5;
}

.expand-btn {
  /* padding-left: 5px; */
  position: absolute;
  background: #cecece;
  right: -1em;
  border-radius: 1em;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  background-color: #ff6600;
}

.expand-btn:hover {
  cursor: pointer;
  background-color: #cecece;
  transition: all 0.5s ease;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.table-container {
  max-height: 20.1em;
  overflow-x: hidden;
}

.table-container1 {
  max-height: 40vh;
  /* width: max-content; */
  width: 99vw;
  /* overflow-x: scroll; */
}

.weatherModalContainer {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 95%;
  width: 95%;
  display: flex;
  /* justify-content: center; */
  align-items: flex-end;
  left: 2em;
}

.weatherModal {
  z-index: 1;
  width: 30%;
  /* min-width: 50%; */
  height: 30em;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 0.5em;
  transition: all 0.5s ease;
}

.weatherModal1 {
  width: 25em;
  height: 25em;
}

.sidebar-table th {
  background: #ff6600;
  text-align: left;
  padding: 2px 5px 2px 10px;
  font-weight: bold;
  line-height: 2em;
}

.sidebar-table td {
  background: #f8a666;
}

.sidebar-table thead th {
  color: white;
  text-align: center;
  width: 25em;
  position: sticky;
  top: 0px;
  z-index: 1;
  font-weight: bold;
  text-transform: capitalize;
}

.sidebar-table td {
  padding: 2px 5px 2px 10px;
}

.sidebar-table tbody {
  font-size: 12px;
}

/* .sidebar-table tbody tr:nth-child(even) {
    opacity: 0.8;
  } */
.sidebar-table tbody tr.parent th {
  text-align: center;
  font-weight: 800;
  font-size: 13px;
}

.sidebar-table tbody tr.child {
  opacity: 0.9;
  font-size: 12px;
}

.customLegend {
  width: 19.5em;
}

.smallLabel {
  font-size: 12px;
  font-weight: bold;
}

.weatherLegend {
  width: 18.5em;
}

.items {
  display: flex;
  flex-direction: column;
  height: 23em;
  justify-content: space-around;
  align-items: center;
  width: 3em;
}

.item {
  /* border-bottom: 1px solid grey; */
  margin: 5px;
  cursor: pointer;
}

.item:hover {
  color: #ff6600;
  transition: all 1s;
}

.title {
  color: #ff6600;
  font-weight: bold;
  text-align: start;
  padding: 10px;
}

.tab-body {
  padding: 0px 10px;
  max-height: 20em;
  overflow-y: auto;
}

.variable {
  cursor: pointer;
  padding: 5px 0px;
}

.variable:hover {
  color: #ff6600;
  transition: all 1s;
}

.selected {
  color: #ff6600;
  font-weight: bold;
}

.variable-item {
  /* position: absolute;
    top: 1; */
  /* display: flex;
    align-items: center;
    justify-content: space-between; 
    padding-right: 10px; */
}

.down {
  color: #ff8a01;
  transform: rotate(90deg);
  transition: transform 0.1s linear;
}

.right {
  transition: transform 0.1s linear;
}

.legend-container {
  /* margin-left: 5px;
    padding-left: 5px; */
  display: flex;
  align-items: center;
}

.legend-img {
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  height: 32px;
  width: 32px;
  background-color: steelblue;
}

.crops-container {
  display: flex;
  flex-direction: column;
  width: 20em;
}

.crops {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow-y: auto;
  padding: 0px 20px 10px 5px;
}

.crop {
  margin: 5px;
  cursor: pointer;
}

.crop:hover {
  color: #ff6600;
  transition: all 1s;
}

.crop-disabled {
  margin: 5px;
}

.land {
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-size: 10px;
}

.legendWithValue {
  display: flex;
  flex-direction: column;
}

.crops-validation-container {
  width: 20em;
  background-color: #f5f5f5;
  border-radius: 1em;
  min-width: 287px;
}

.crop-validation {
  display: flex;
  padding: 5px;
}

.crop-validation-variables {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 5px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1em;
  max-height: 45vh;
}

.crop-validation-variables .scroll-view {
  overflow-y: auto;
}

.crop-validation-variable {
  line-height: 2em;
}

.crop-validation-variable:hover {
  cursor: pointer;
  color: #ff6600;
  font-weight: bold;
  transition: all 1s;
}

.toggleBtn {
  left: 1em;
  position: absolute;
  border-radius: 1em;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s ease-in-out;
}

.toggleBtn img {
  height: 2em;
}

.toggleBtn .imageDisabled {
  filter: grayscale(1);
}

.chart-header {
  width: 100%;
  text-align: center;
  color: #ff6600;
  font-weight: bold;
}

.chart-header p {
  margin-left: 5px;
  margin-right: 10px;
}

.rainChart {
  width: 32em;
  background-color: #f5f5f5;
}

.minimize-btn {
  position: absolute;
  left: -1em;
  border-radius: 1rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ff6600;
}

.arrowDown {
  transform: rotate(90deg);
  transition: transform 0.5s ease-in-out;
}

.arrowUp {
  transform: rotate(270deg);
  transition: transform 0.5s ease-in-out;
}

.minimize-btn:hover {
  background-color: #cecece;
  transition: all 0.5s ease-in-out;
}

.open {
  opacity: 1;
  transition: all 0.5s ease-in;
}

.closed {
  opacity: 0;
  height: 0;
  width: 5px;
  transition: all 0.5s ease-out;
}

.close-btn {
  position: absolute;
  right: 0.5em;
  background: #ff6600;
  border-radius: 1em;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #cecece;
  transition: all 0.5s ease;
}

.showStat {
  opacity: 0.9;
  overflow: hidden;
  max-height: 50em;
  transition: all 0.8s ease-in-out;
}

.hideStat {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}

.subtitle {
  position: absolute;
  top: 2.5em;
  font-size: 0.9rem;
  font-family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
  width: 100%;
  text-align: center;
  padding: 2px;
}
