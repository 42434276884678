@media only screen and (orientation: portrait) {
  .overlay {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: block !important;
  }
  .image-login {
    display: none !important;
  }
  .login-card {
    flex: 1;
    height: 100vh;
  }
  .app-wrapper {
    @include linear-gradient($topbarBgColor, 30%, #fff, 10%, $topbarBgColor);
  }
  .layout-toolbar {
    .layout-topbar-left {
      flex-shrink: 0;
      width: 100%;
    }
    .layout-topbar-right {
      width: 100%;
    }
  }
  .container {
    // margin-top: 100px;
    .sidebar {
      // width:0px;
      // overflow: hidden;
      position: absolute;
      z-index: 1001;
      // height: 100%;
      .app-profile {
        height: 147px;
        .profile-img {
          height: 80px;
          width: 80px;
        }
      }
    }
  }
  .mcd-menu {
    // overflow: hidden;
  }
}
