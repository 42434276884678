.loader {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;
}

span.loader__ball {
  display: inline-block;
  margin: auto 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1rem;
  background: #ff9a5c;

  &.loader__ball--1,
  &.loader__ball--2,
  &.loader__ball--3 {
    animation: bulging 2s infinite ease-in-out;
  }

  &.loader__ball--1 {
    animation-delay: -0.4s;
  }

  &.loader__ball--2 {
    animation-delay: -0.2s;
  }

  @keyframes bulging {
    0%,
    80%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
