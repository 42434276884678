.card {
  position: relative;
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 100%;
  // box-shadow: 0px 0px 5px 5px #cccccc;
  //   margin: 5px;
  .card-body {
    position: relative;
    flex: 1;
    padding: 5px;
    display: flex;
    flex-direction: column;
    h2 {
      color: $topbarBgColor;
      font-size: 1.3rem;
      padding-left: 10px;
    }
  }
  .card-header {
    background: $topbarBgColor;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
